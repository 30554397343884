<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="lp-cta-widget-tour-form f-form"
    @submit.prevent="onSubmit"
  >
    <q-carousel
      ref="elQCarousel"
      v-model="slide"
      swipeable
      animated
      control-color="secondary"
      :arrows="false"
      :height="$q.screen.gt.sm ? 'auto' : 'inherit'"
    >
      <q-carousel-slide
        v-for="(tourDays, i) in chunkedTourDays"
        :key="i"
        :name="i"
        class="lp-cta-tour-days__container"
        arrows
      >
        <q-btn
          class="lp-cta-widget__navigation-btn slide-btn-left"
          icon="chevron_left"
          unelevated
          size="xs"
          dense
          round
          @click="elQCarousel?.previous()"
        />
        <q-btn
          class="lp-cta-widget__navigation-btn slide-btn-right"
          icon="chevron_right"
          unelevated
          dense
          size="xs"
          round
          @click="elQCarousel?.next()"
        />
        <q-btn
          v-for="(tourDay, j) in tourDays"
          :key="j"
          stack
          no-caps
          no-wrap
          :class="{
            'tour-day-btn': true,
            selected: tourDay.full === formData.date,
            unavailable: tourDay.unavailable,
          }"
          :disable="tourDay.unavailable"
          unelevated
          @click="onClickBtnTourDay(tourDay, j)"
        >
          <div class="tour-day--text tour-day--day-short-text" v-text="tourDay.day.short" />
          <div class="tour-day--text tour-day--day-number" v-text="tourDay.day.numeric" />
          <div class="tour-day--text tour-day--month-short-text" v-text="tourDay.month.short" />
        </q-btn>
      </q-carousel-slide>
    </q-carousel>

    <FInputSelect
      ref="elFInputSelect"
      v-model="formData.timeSlot"
      :error="!!errors?.timeSlot?.[0]"
      :error-message="errors?.timeSlot?.[0]"
      :label="t('LPTourDay.fields.timeSlot.label')"
      :no-select-option-text="t('LPTourDay.fields.timeSlot.placeholder')"
      :options="timeSlots"
      outlined
      popup-content-class="f-input-select--time-slot"
      :rules="[requiredTimeSlot]"
    />

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import chunk from 'lodash/chunk';
import { storeToRefs } from 'pinia';
import type { QCarousel } from 'quasar';
import { computed, onBeforeUnmount, onMounted, reactive, ref, useTemplateRef, watch } from 'vue';
import { useI18n } from 'vue-i18n';

import FInputSelect from '@/components/Form/input/FInputSelect.vue';
import { useFormInputRules } from '@/composables/formInputRules';
import { useUtmSource } from '@/composables/useUtmSource';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import useListingStore from '@/store/modules/listing';
import { CommercializationType, type DateItemExtended } from '@/types';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FormId, Next } from '@/types/formStepsFactory';
import { toDateTime } from '@/utils/time';
import { getBusinessDays, getTimeSlots, initializeTourConfig } from '@/utils/tourScheduler';

const { elQForm, required } = useFormInputRules();
const { isPreview, listingConfig, listing } = storeToRefs(useListingStore());
const { resolveUtmSource } = useUtmSource();
const { t, locale } = useI18n(tFormSteps);

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
  next?: Next;
}>();

const emit = defineEmits<{
  (e: 'next', p: Next): void;
}>();

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const bus = useEventBus(formStepperKey);

const slide = ref(0);
const loading = ref(false);
const errors = ref<Partial<Record<keyof typeof formData, string[]>>>({});

const chunkedTourDays = ref<ReturnType<typeof getBusinessDays>[]>([]);
const selectedYourDayIndex = ref(-1);

const elQCarousel = useTemplateRef<InstanceType<typeof QCarousel>>('elQCarousel');

const requiredTimeSlot = required();

const formData = reactive({
  listingId: listing.value!.id,
  date: '',
  timeSlot: '',
  utmSource: resolveUtmSource(),
});

const btnPropsSubmit = computed(() => ({
  class: 'text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value || !formData.timeSlot,
  form: `f-${props.formId}`,
  label: t('LPTourDay.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const onClickBtnTourDay = (tDay: DateItemExtended, index: number) => {
  if (tDay.unavailable) return;

  selectedYourDayIndex.value = index;
  formData.date = tDay.full;
};

const timeSlots = computed(() => {
  const dayOfWeek = formData.date ? new Date(formData.date).getDay() : -1;

  return getTimeSlots().find(el => el.day === dayOfWeek)?.slots || [];
});

const initializeCalendar = () => {
  initializeTourConfig(listingConfig.value?.tourDays.options);

  chunkedTourDays.value = chunk(getBusinessDays(16, locale.value), 4);

  const firstAvailableDate = chunkedTourDays.value[0].find(i => !i.unavailable);
  if (firstAvailableDate) {
    formData.date = firstAvailableDate.full;
  }
};

const successSubtitle = computed(() => {
  if (!formData.timeSlot || !formData.date) return '';

  const makeTime = formData.timeSlot.split('-')[0].trim();
  const makeDateTime = new Date(formData.date);
  const [hours, minutes] = makeTime.split(':').map(n => parseInt(n, 10));
  makeDateTime.setHours(hours);
  makeDateTime.setMinutes(minutes);

  const dateTime = toDateTime(makeDateTime, false, 'dddd, D/M, HH:mm', locale.value);
  const meridian =
    makeDateTime.getHours() >= 12 ? t('LPTourDay.extra.pm') : t('LPTourDay.extra.am');
  return `${dateTime}${meridian}`;
});

const onSubmit = async () => {
  errors.value = {};

  if (isPreview.value) return;

  const v = await elQForm.value?.validate();
  if (!v) return;

  loading.value = true;

  const gtmEvent =
    listing.value?.commercializationType === CommercializationType.RENTAL
      ? 'request_viewing_rent_success'
      : 'request_viewing_sale_success';

  emit('next', { formData, gtmEvent, successSubtitle: successSubtitle.value });
};

onMounted(() => {
  initializeCalendar();
});

watch(locale, initializeCalendar);

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-tour-day') {
    switch (e.event) {
      case 'abort':
        loading.value = false;
        break;
      case 'complete':
        loading.value = false;
        break;
      default:
        break;
    }
  }
};

bus.on(busListener);

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>

<style lang="scss">
@use 'sass:map';
@use '@/css/color_pallette' as c;

.lp-cta-widget-tour-form {
  .slide-btn-left {
    left: 0;
  }

  .slide-btn-right {
    right: 0;
  }

  .lp-cta-widget__navigation-btn {
    position: absolute;
    top: 50%;
    z-index: 1;
    color: c.$secondary;
    transform: translateY(-50%);

    .q-icon {
      width: 0.75rem;
      height: 0.75rem;
    }
  }

  .q-field.q-field--outlined .q-field__control {
    border-radius: map.get($radius-sizes, 'md');
  }
}

.f-input-select--time-slot {
  max-height: 250px;
  font-weight: 600;
  color: c.$secondary;
  border: 1px solid c.$util-2;
}

.tour-day-btn {
  width: 65px;
  height: 85px;
  color: c.$secondary;
  background: white;
  border: 1px solid c.$util-2;
  border-radius: map.get($radius-sizes, 'sm');

  &:not(.disabled):hover {
    color: white;
    background: c.$primary;
  }

  &.selected {
    color: white;
    background: c.$primary;
    border: 1px solid c.$primary;
  }

  &.unavailable {
    color: rgb(0 0 0 / 20%);
  }

  @media (max-width: $breakpoint-xs) {
    width: 50px;
  }
}

.tour-day--text {
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 1.6;
}

.tour-day--day-number {
  font-size: 1.25rem;
  font-weight: 800;
}

.lp-cta-tour-days__container {
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
}
</style>
