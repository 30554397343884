<template>
  <q-form
    :id="btnPropsSubmit.form"
    ref="elQForm"
    class="f-form-open-days"
    @submit.prevent="onSubmit"
  >
    <div class="text-body2 text-accent" v-text="t('LPOpenDay.extra.header')" />

    <q-carousel
      v-model="slide"
      swipeable
      control-color="secondary"
      arrows
      animated
      :height="$q.screen.gt.sm ? 'auto' : 'inherit'"
    >
      <q-carousel-slide
        v-for="(openDays, i) in chunkedOpenDates"
        :key="i"
        :name="i"
        :class="{ 'row justify-center': $q.screen.lt.md }"
      >
        <q-btn
          v-for="(openDay, j) in openDays"
          :key="j"
          :class="{
            'lp-cta-widget-open-day__btn': true,
            'lp-cta-widget-open-day__btn--selected': j === selectedOpenDayIndex,
          }"
          stack
          no-caps
          no-wrap
          unelevated
          @click="onClickBtnOpenDay(openDay, j)"
        >
          <div
            class="text-center text-body1-bolder"
            v-text="toDate(openDay.date, false, 'dddd, MMM D', $i18n.locale)"
          />
          <div
            class="text-center text-body2"
            v-text="getTimeSlot(openDay.timeFrom, openDay.timeTo)"
          />
        </q-btn>
      </q-carousel-slide>
    </q-carousel>

    <Teleport defer :disabled="!isDialog" :to="teleportTarget">
      <q-btn v-bind="btnPropsSubmit" />
    </Teleport>
  </q-form>
</template>

<script setup lang="ts">
import { useEventBus } from '@vueuse/core';
import chunk from 'lodash/chunk';
import { storeToRefs } from 'pinia';
import { Screen } from 'quasar';
import { computed, onBeforeUnmount, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';

import { useUtmSource } from '@/composables/useUtmSource';
import tFormSteps from '@/i18n/translations/components/formSteps.json';
import useListingStore from '@/store/modules/listing';
import type { OpenDayConfig } from '@/types/api/listing';
import { formStepperKey, type PayloadFormStepper } from '@/types/event-bus';
import type { FormId } from '@/types/formStepsFactory';
import { toDate } from '@/utils/time';

const props = defineProps<{
  formId: FormId;
  isDialog?: boolean;
}>();

const emit = defineEmits<{
  (e: 'next', p: { formData: typeof formData }): void;
}>();

const teleportTarget = computed(() => `#${props.formId} .dialog-form--actions`);

const bus = useEventBus(formStepperKey);

const { t } = useI18n(tFormSteps);
const { isPreview, listing, listingConfig } = storeToRefs(useListingStore());
const selectedOpenDayIndex = ref(-1);

const formData = reactive({
  listingId: listing.value?.id || '',
  date: '',
  timeSlot: '',
  utmSource: useUtmSource().resolveUtmSource(),
});

const loading = ref(false);

const btnPropsSubmit = computed(() => ({
  class: 'full-width text-body2-bold border-radius-xl',
  color: 'primary',
  disable: loading.value || !(formData.date && formData.timeSlot),
  form: `f-${props.formId}`,
  label: t('LPOpenDay.btn.submit'),
  noCaps: true,
  padding: '0.8rem',
  textColor: 'white',
  type: 'submit',
  unelevated: true,
}));

const onSubmit = () => {
  if (isPreview.value) return;

  loading.value = true;

  emit('next', { formData });
};

const slide = ref(0);

const getTimeMeridian = (time: string) => {
  const makeTime = time.split(':');
  return `${makeTime[0]}:${makeTime[1]} ${makeTime[0] >= '12' ? t('LPOpenDay.extra.pm') : t('LPOpenDay.extra.am')}`;
};

const getTimeSlot = (from: string, to: string) =>
  `${getTimeMeridian(from)} - ${getTimeMeridian(to)}`;

const chunkedOpenDates = computed(() => {
  let size = 2;
  switch (Screen.name) {
    case 'xs':
      size = 6;
      break;
    case 'sm':
      size = 4;
      break;
    default:
      size = 2;
      break;
  }

  return chunk(listingConfig.value?.openDays.options, size);
});

const onClickBtnOpenDay = (openDay: OpenDayConfig, j: number) => {
  const date = toDate(openDay.date, false, 'YYYY-MM-DD');
  if (!date) return;

  selectedOpenDayIndex.value = j;

  formData.date = date;
  formData.timeSlot = [openDay.timeFrom, openDay.timeTo].join(' - ');
};

const busListener = (e: PayloadFormStepper) => {
  if (e.factoryId === 'lp-open-day') {
    switch (e.event) {
      case 'abort':
        loading.value = false;
        break;
      case 'complete':
        loading.value = false;
        break;
      default:
        break;
    }
  }
};

bus.on(busListener);

onBeforeUnmount(() => {
  bus.off(busListener);
});
</script>

<style lang="scss">
@use 'sass:map';

.f-form-open-days {
  .q-carousel {
    .q-carousel__control {
      .q-btn {
        width: 1rem;
        height: 1rem;
      }

      .q-icon svg {
        width: 0.75rem;
        height: 0.75rem;
      }
    }

    .q-carousel__next-arrow--horizontal {
      margin-right: -1rem;
    }

    .q-carousel__prev-arrow--horizontal {
      margin-left: -1rem;
    }

    .q-carousel__slide {
      display: flex;
      gap: 0.5rem;
      align-items: center;
      justify-content: center;
      padding: 1rem 2rem;
    }
  }
}

.lp-cta-widget-open-day__btn {
  flex: 1;
  padding: 0.75rem 1rem;
  color: $secondary;
  border: 0.0625rem solid $util-1;
  border-radius: map.get($radius-sizes, 'sm');

  &:hover {
    color: white;
    background: $primary;
  }
}

.lp-cta-widget-open-day__btn--selected {
  color: white;
  background: $primary;
  border-color: $primary;
}
</style>
